<template>
    <v-row
        justify="center"
    >
        
        <v-col>        
            <v-card
                class="mx-auto pa-0"
                width="1000"
                height="80vh"
                flat
            >
                <v-card-title
                    class="d-flex white--text pb-4 px-0"
                >
                    <v-spacer></v-spacer>
                    <div class=" font-weight-bold primary--text text-uppercase">{{ academicYear }} Term {{ term }} - {{ lessonSelected.subjectTitle }} Mark Sheet</div>
                    <v-spacer></v-spacer>
                    <v-btn
                        icon               
                        color="red"
                        @click="displayMarkSheetMenu"
                    >
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn>            
                </v-card-title>

                
                <v-row class=" mt-4">
                    <v-col class="py-0">
                        <Header
                            @filter-students-by-class="filterStudentsByClass"
                        />
                        
                        <MarkSheetTable
                            :selectedClasses="selectedClasses"
                        />
                    </v-col>
                </v-row>
                

                <v-card-actions class="mt-4">
                    <v-spacer></v-spacer>
                    <v-btn depressed @click="displayMarkSheetMenu">Close Mark Sheet</v-btn>
                </v-card-actions>
               
                <v-overlay
                    absolute
                    :value="overlay"                    
                    color="grey lighten-5"
                    opacity="0.9"
                    class="pa-5"
                >                    
                    
                    <Lesson 
                        v-if="displayLessons"
                    />
                    
                    <Teacher 
                        v-if="displayTeachers"
                    />

                    <v-progress-circular 
                        transition="fade-transition" 
                        indeterminate 
                        size="64"
                        v-if="loading"
                        color="primary"
                    ></v-progress-circular> 
                </v-overlay>
            </v-card>
        </v-col>
        
    </v-row>    
</template>

<script>
import Header from './MarkSheetHeader'
import Lesson from './MarkSheetLesson'
import Teacher from './MarkSheetTeacher'
import MarkSheetTable from './MarkSheetTable'
import { mapGetters, mapMutations } from 'vuex'
export default {
    name: 'MarkSheet',

    created () {
        this.initialize();
    },
  
    components: {
        Header,
        Lesson,
        Teacher,
        MarkSheetTable,
    },

    computed: {
        ...mapGetters({            
            overlay: 'markSheet/getMarkSheetOverlay',
            loading: 'markSheet/getMarkSheetLoading',
            totalStudents: 'markSheet/getTotalStudents',
            admin: 'auth/getAdmin',
            displayLessons: 'markSheet/getDisplayLessons',
            displayTeachers: 'markSheet/getDisplayTeachers',
            lessonSelected: 'markSheet/getLessonSelected',
            academicYear: 'app/getAcademicYear',
            term: 'app/getTerm',
        }), 
    },
    
    watch: {
        displayTeachers: {
            handler (val) {
                console.log(`Display Teachers: ${val}`);
            }
        }
    },

    data: () => ({
        userTimetable: [],
        selectedClasses: [],
    }),

    methods: {
        ...mapMutations({
            setOverlay: 'markSheet/setMarkSheetOverlay',
            setMarkSheetLoading: 'markSheet/setMarkSheetLoading',
            setDisplayLessons: 'markSheet/setDisplayLessons',
            setDisplayTeachers: 'markSheet/setDisplayTeachers',                       
        }),

        initialize () {
            this.displayMarkSheetMenu()               
        },
                      
        displayMarkSheetMenu () {
            this.setOverlay(true);
            if(this.admin){
                this.setDisplayLessons(false);
                this.setDisplayTeachers(true);                
            }
            else {
                this.setDisplayLessons(true);
                this.setDisplayTeachers(false);
            }           
        },

        filterStudentsByClass (selectedClasses) {
            this.selectedClasses = selectedClasses
        }
       
    }
}
</script>