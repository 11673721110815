<template>
    <v-col
        class="py-0"        
    >
        <v-row>
            <v-card
                width="640"
                class="mx-3 mt-3 pa-1"
                flat
                outlined                                
            >
                <v-data-table
                    :headers="headers"
                    :items="studentTermMarks"
                    dense                    
                    fixed-header
                    height="320"
                    disable-pagination
                    hide-default-footer
                    calculate-widths  
                >
                    <template v-slot:item="props">
                        <subject-row
                            v-bind:record="props.item"
                        ></subject-row>
                    </template>
                    
                </v-data-table>
                <v-overlay
                    absolute
                    :value="overlay"
                    color="grey"
                    opacity="0.6"
                >
                    <v-progress-circular
                        indeterminate 
                        size="64"                        
                        color="primary"
                    ></v-progress-circular>
                </v-overlay>    
            </v-card>
        </v-row>

        <v-row
            class="mt-3 ml-2"                                
            align="center"
        >
            <v-col class="pa-0">
                <v-card
                    height="110"
                    width="520"                    
                    flat                    
                >
                    <v-form ref="form">
                        <v-textarea                                       
                            label="Form Teacher's Comments"                           
                            dense
                            rows="2"
                            :value="value"                        
                            counter                                            
                            class="text-caption"                                              
                            no-resize
                            maxlength="215"
                            v-model="studentRecord.teacher_comment"                            
                            @blur="saveTeacherComment"
                            @click="openBottomSheet('form-teacher')"
                            :disabled="disabled"
                            :filled="disabled"                     
                        >                        
                        </v-textarea>
                    </v-form>
                </v-card>
            </v-col>                  
            <v-col
                class="pt-0 pb-7"
            >
                <v-card
                    width="90"
                    height="80"
                    flat
                    outlined
                    class=""                    
                >
                    <v-card-title
                        class="text-caption pb-0"
                    >Average</v-card-title>
                    <v-card-text
                        class="text-center text-h6 font-weight-bold px-0 primary--text"
                    >
                        {{ studentAverage }}
                    </v-card-text>
                </v-card>
            </v-col>                               
        </v-row>
        <v-row>
            <v-card
                    
                width="530"
                class="px-3"
                flat 
                                    
            >                   
                <v-textarea                                       
                    label="Dean's Comments"                            
                    dense
                    rows="2"                                                    
                    counter                                            
                    class="text-caption"                                                
                    no-resize
                    maxlength="200"
                    v-model="studentRecord.dean_comment"                            
                    @blur="saveDeanComment"
                    @click="openBottomSheet('dean')"
                    :disabled="!deanAccess"
                    :filled="!deanAccess"                     
                >                        
                </v-textarea>
                    
            </v-card>
        </v-row>        
    </v-col>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import SubjectRow from './EditViewTermReportsSubjectRow'
export default {
    components: {
        SubjectRow,
    },    
    data: () => ({
        headers: [           
            {text: 'Subject', align: 'left', value: 'abbr', width: '140'},
            {text: 'Course', align: 'center', value: 'course_mark', width: '70'},
            {text: 'Exam', align: 'center', value: 'exam_mark', width: '70'},
            // {text: 'Conduct', align: 'center', value: 'conduct', width: '60'},           
            {text: 'Comment', align: 'left', value: 'comment'},
            {text: 'Teacher', align: 'left', value: 'employee_id', width: '100'},
            {text: '', align: 'left', value: 'action', width: '40'}
        ],             
        studentAverage: '',        
        value: ' ',       
        isValid: true, 
        record: {},
        subjectRecord: [],
        disabled: true,
        deanAccess: false,      
                
    }),       
    watch: {
        studentRecord: {
            handler(){
                if(this.formClassAssignment.includes(this.studentRecord.form_class_id)){                  
                    this.disabled = false;
                }
                else{                   
                    this.disabled = true;
                }

                if(this.formDeanAssignments.includes(this.studentRecord.form_class_id)){                   
                    this.deanAccess = true;
                }
                else{                    
                    this.deanAccess = false;
                }
            }
        },
        sheet: {
            handler (val) {               
                if(!val) this.updateRecord();
            }
        },
        studentTermMarks: {
            deep: true,
            handler(){
                this.getAverage();
                //console.log(this.studentTermMarks);
            }
        }        
    },
    computed: {
        ...mapGetters({
            studentRecord: 'termReports/getStudentRecord',
            studentTermMarks: 'termReports/getStudentTermMarks',
            formClassAssignment: 'termReports/getFormClassAssignment',            
            formDeanAssignments: 'termReports/getFormDeanAssignments',
            sheet: 'termReports/getSheet',
            overlay: 'termReports/getOverlaySubjectRecords',
            courseWorkOnly: 'termReports/getCourseWorkOnly',            
        }),
       
    },
    methods: {
        ...mapMutations({            
            setSaveStatus: 'termReports/setSaveStatus',
            setSaving: 'termReports/setSaving',
            setSaveError: 'termReports/setError',
            setSaved: 'termReports/setSaved',
            setCommentsSheet: 'termReports/setSheet',
            setCommentsSheetTitle: 'termReports/setSheetTitle',
            setCommentsSheetModel: 'termReports/setSheetModel',
            setSavedToDb: 'termReports/setSavedToDb',
            setUpdating: 'termReports/setUpdating', 
        }),
        ...mapActions({
            getTermMarks: 'termReports/getTermMarks',
            postStudentDetails: 'termReports/postStudentTermDetails',
            // setErrorResponse: 'termReports/setErrorResponse', 
        }),
        getAverage(){
            let recordCount = 0;
            let markTotal = 0;
            let average = 0;
            //console.log(this.studentTermMarks);
            this.studentTermMarks.forEach(record => {
                recordCount++;
                //console.log(this.courseWorkOnly);
                if(this.courseWorkOnly && !isNaN(record.course_mark)){
                    markTotal += record.course_mark;
                    //console.log(record.course_mark);
                }                
                else if(!isNaN(record.exam_mark)){
                    markTotal += record.exam_mark;
                    //console.log(record.exam_mark);
                }
                else{
                    //console.log('nothing');
                } 
            })
            if(recordCount != 0){
                average = (markTotal / recordCount);                
                this.studentAverage = average.toPrecision(3) + ' %';
                //this.studentAverage = markTotal;
            }
            else{
                average = 0;
                this.studentAverage = '-'
            }
            
        },
        saveTeacherComment(){
            if(this.studentRecord.teacher_comment) this.updateRecord();            
        },
        saveDeanComment(){
            if(this.studentRecord.dean_comment) this.updateRecord();
        },
        openBottomSheet(post){
            console.log(post);
            if(post == 'form-teacher'){
                this.setCommentsSheetTitle('Form Teacher Comments');
                this.setCommentsSheetModel('teacher_comment');
            } 
            else if (post == 'dean'){
                this.setCommentsSheetTitle('Dean Comments'); 
                this.setCommentsSheetModel('dean_comment')
            }            
            this.setCommentsSheet(true);
            //console.log(`sheet: ${ this.sheet }`);
        },
        async updateRecord(){
            console.log('updating...');
            this.setUpdating(true);
            this.setSaveStatus('Saving...');
            this.setSaving(true);
            this.setSaved(false);
            this.setSaveError(false);
            this.setSavedToDb(false);
            this.setUpdating(false);
            console.log(this.studentRecord);
            try{
                let response = await this.postStudentDetails();
                this.setSaveStatus('Record Saved');
                this.setSaving(false);
                this.setSaved(true);
                this.setSaveError(false);
                console.log(response.status);
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.setSaveStatus('Error Occured');
                this.setSaving(false);
                this.setSaved(false);
                this.setSaveError(true);
                this.setErrorResponse(error);
            }
            
        },           
    }
}
</script>

<style>
    ::v-deep .v-text-field__slot textarea{
        line-height: 1rem;
        font-size: 13px;
    }
</style>