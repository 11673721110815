<template>
    <v-dialog
        v-model="dialog"
        persistent
        width="520"
    >
        <v-card
            class="pa-4"
        >
            <v-tabs
                v-model="tab"
                align-with-title
                grow
            >
                <v-tabs-slider color="primary"></v-tabs-slider>
                <v-tab>Current Term</v-tab>
                <v-tab>Term Archives</v-tab>
            </v-tabs>
            
            
            <v-tabs-items v-model="tab">
                <v-tab-item>
                    <v-card 
                        flat
                        :height="height"
                    >
                        <v-card-text>
                            <v-select
                                label="Term"
                                :items="terms"
                                v-model="term"
                                prepend-icon="mdi-numeric-1-box-multiple-outline"
                            ></v-select>
                            <date-picker                    
                                v-bind:label="labelTermStartDate"
                                v-bind:initialDate="dateTermStart"                    
                            ></date-picker>

                            <date-picker                    
                                v-bind:label="labelTermEndDate"
                                v-bind:initialDate="dateTermEnd"
                            ></date-picker>

                            <date-picker                    
                                v-bind:label="labelNextTermStartDate"
                                v-bind:initialDate="dateNextTermStart"
                            ></date-picker>

                            <v-text-field
                                label="Total Number of Sessions"
                                prepend-icon="mdi-counter"
                                v-model="totalSessions"
                            ></v-text-field>
                        </v-card-text>                        
                    </v-card>
                </v-tab-item>

                <v-tab-item>
                    <v-card 
                        flat
                        :height="height"
                    >
                        <v-card-title>
                            
                            <v-spacer></v-spacer>
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search Term"
                                single-line
                                hide-details
                                clearable
                            ></v-text-field>
                        </v-card-title>

                        <v-data-table
                            :headers="headersTermHistory"
                            :items="availableTerms"
                            :search="search"
                            hide-default-footer
                            :loading="loading"
                            :custom-filter="filterTerms"
                            fixed-header
                            height="50vh"
                        >
                            
                            <template v-slot:body="{ items }">
                                <tbody>
                                    <tr
                                        v-for="item in items"
                                        :key="item.id"
                                    >
                                        <td>
                                            {{ item.title }}
                                        </td>                                        
                                        <td>
                                            <div 
                                                v-if="item.current == 1"
                                                class="d-flex flex-column align-start"
                                            >
                                                <v-icon 
                                                    color="primary"                                                    
                                                >
                                                    mdi-clock-check-outline
                                                </v-icon>
                                                <div style="font-size:0.7rem" class="primary--text">Current</div>
                                            </div>
                                        </td>
                                        <td>
                                            <v-btn
                                                small
                                                outlined
                                                color="primary"
                                                @click="backdateTerm(item)"
                                                :disabled="item.current == 1"
                                            >Set Term</v-btn>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>

                        </v-data-table>

                        
                    </v-card>
                </v-tab-item>    
            </v-tabs-items>

            <v-card-actions>                
                <v-row justify="space-between">
                    <v-btn
                        :disabled="!advanceTerm"
                        dark
                        color="primary"
                        @click="validate('insert')"
                    >
                        Set Term
                    </v-btn>
                    <v-btn
                        :disabled="!advanceTerm"
                        dark   
                        :color="!advanceTerm ? 'primary' : 'secondary'"
                        @click="validate('update')"
                    >
                        <span :class="!advanceTerm ? 'text--white' : 'text--primary'">Update Term Details</span>
                    </v-btn>                 
                    <v-btn                    
                        depressed
                        @click="close"
                        :class="closeButtonClass"
                    >
                        Close
                    </v-btn>
                </v-row>                
            </v-card-actions>
          
            
            <v-overlay
                :value="overlay"
                opacity="0.8"
                z-index="10"
                absolute                    
            >
                
                <v-progress-circular
                    indeterminate
                    size="64"
                ></v-progress-circular>                   
            </v-overlay>
        </v-card>

        <v-snackbar
            v-model="snackbar"
            timeout="5000"
            :color="snackColor"
        >
            {{ snackText }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>

    </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import DatePicker from './DatePicker';

export default {
    components: {
        DatePicker,
        
    },

    props: {
        dialogOpen: Boolean,
    },

    created: function () {
        this.initialize();
    },    

    data: function () {
        return {
            dialog: this.dialogOpen,       
            dateTermStart: {date: null},
            dateTermEnd: {date: null},
            dateNextTermStart: {date: null},
            labelTermStartDate: 'Current Term Start',
            labelTermEndDate: 'Current Term End',
            labelNextTermStartDate: 'New Term Beginning',
            totalSessions: null,
            term: null,
            terms: [1,2,3],        
            overlay: false,       
            tab: null,
            height: '500',
            availableTerms: [],
            selectedItem: -1,
            advanceTerm: false,
            snackbar: false,
            snackText: '',
            snackColor: '',
            closeButtonClass: '',
            search: '',
            headersTermHistory: [
                { text: 'Term', value: 'title', width: '200'},            
                { text: 'Current', value: 'current', align: 'center' },
                { text: '', value: 'actions', sortable: false}
            ],
            loading: false, 
        } 
    },
    
    watch: {
        tab: {
            handler (val) {
                this.error = false;
                this.saved = false
                this.errorMessage = null;
                this.successMessage = null;                
                if(val == 1){
                    this.advanceTerm = false;
                    this.closeButtonClass = '';
                } 
                else{
                    this.advanceTerm = true;
                    this.closeButtonClass = ''
                } 
            }
        }
    },

    computed: {
        ...mapGetters({
            backdateAcademicTermId: 'settings/getBackdateAcademicTermId',
        })
    },

    methods: {
        ...mapMutations({
            setDialog: 'settings/setDialogSetTerm',
            setSelectedItem: 'settings/setSelectedItem',
            setCurrentTerm: 'settings/setCurrentTerm',
            setTermRegistration: 'settings/setTermRegistrationData',
            setAcademicTermId: 'app/setAcademicTermId',
            setAcademicYearId: 'app/setAcademicYearId',
            setAppTerm: 'app/setTerm',
            setCurrentAcademicPeriod: 'app/setCurrentAcademicPeriod',
            setBackdateAcademicTermId: 'settings/setBackdateAcademicTermId',
        }),
        
        ...mapActions({
            getCurrentTerm: 'settings/getCurrentTerm',
            getNextTerm: 'settings/getNextTerm',
            postCurrentTerm: 'settings/postCurrentTerm',
            postTermRegistration: 'settings/postTermRegistration',
            getTerms: 'settings/getAvailableTerms',
            setBackdateTerm: 'settings/backdateTerm',
        }),

        async initialize () {
            let response = null;
            this.loading = true;
            try {
                const currentTermPromise = this.getCurrentTerm();
                const nextTermPromise = this.getNextTerm();
                const termsPromise = this.getTerms();

                response = await currentTermPromise;
                this.dateTermStart.date = response.data.term_start;
                this.dateTermEnd.date = response.data.term_end;
                this.term = response.data.term;
                this.totalSessions = response.data.total_sessions;               

                response = await nextTermPromise;                
                this.dateNextTermStart.date = response.data.term_start;

                response = await termsPromise;
                console.log(response);
                this.availableTerms = response.data.map((value, index) => {
                    if(value.current == 1) this.selectedItem = index;
                    return {
                        id: value.id,
                        title: value.year + ' Term ' + value.term,
                        subtitle: value.period,
                        current: value.current                                               
                    }
                })              
                
                
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
            this.loading = false;
            this.overlay = false;
        },

        validate (action) {
            this.overlay = true;
            if(this.dateTermStart.date && this.dateTermEnd.date && this.dateNextTermStart.date){
                this.setTerm(action);
            }
            else{
                this.errorMessage = "Information Missing."
                this.error = true;
                this.overlay = false;
            }
        },

        async setTerm (action) {
            this.setCurrentTerm({
                term: this.term,
                term_start: this.dateTermStart.date,
                term_end: this.dateTermEnd.date,
                total_sessions: this.totalSessions,
                next_term_start: this.dateNextTermStart.date
            });


            this.setTermRegistration({
                new_term_beginning: this.dateNextTermStart.date,
                total_sessions: this.totalSessions
            })

            try {
                const postCurrentTermPromise = this.postCurrentTerm();
                const postTermRegistration = this.postTermRegistration();

                await postCurrentTermPromise;                

                await postTermRegistration;

                this.updateAppTermSettings();

                this.snackbar = true;
                this.snackColor = "primary";
                if(action == 'insert')
                this.snackText = "Term Set Successfully";
                else if(action == 'update')
                this.snackText = "Term Updated Successfully";
                
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);

                this.snackbar = true;
                this.snackColor = "red";
                this.snackText = 'An Error has occured';               
            }
            this.overlay = false;
        },

        async updateAppTermSettings(){
            let response = null, academic_year_id = null, year = null;
            try {
                response = await this.getCurrentTerm();
                academic_year_id = response.data.academic_year_id;
                this.setAcademicTermId(response.data.id);
                this.setAcademicYearId(academic_year_id);
                this.setAppTerm(response.data.term);
                year = academic_year_id.toString().substr(0, 4) + '-' + academic_year_id.toString().substr(4);
                this.setCurrentAcademicPeriod(`Term: ${ response.data.term } Year: ${ year}`); 
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
        },

        close () {            
            // this.setDialog(false);
            // this.setSelectedItem({item: -1});
            // this.tab = 0;
            // this.$nextTick(() => {
            //     this.saved = false;
            //     this.error = false;
            //     this.errorMessage = null;
            // })
            this.$emit('dialog-close');
        },

        async backdateTerm (item) {  
            //console.log(item);          
            this.setBackdateAcademicTermId(item.id);
            this.overlay = true;            
            try {
                await this.setBackdateTerm();
                this.initialize();
                this.snackbar = true;
                this.snackColor = "primary";
                this.snackText = `Term Set to ${item.title}`;               
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.snackbar = true;
                this.snackColor = "red";
                this.snackText = "An Error has occured."                
            }            
        },

        filterTerms (value, search) {
            return value != null &&
            search != null &&
            value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
        }

    }

}
</script>